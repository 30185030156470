import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import './CustomLink.scss';

const CustomLink = ({
  linkLabel,
  color = 'red',
  link,
  isLinkExternal = false,
  className,
}: PageContent.CustomLinkType) => {
  const linkClass = classNames('custom-link', className, {
    [`custom-link--${color}`]: { color },
  });

  return isLinkExternal ? (
    <a href={link.url} className={linkClass} target={link.target} rel="noreferrer noopener">
      {linkLabel}
    </a>
  ) : (
    <Link to={link.url} className={linkClass}>
      {linkLabel}
    </Link>
  );
};

export default CustomLink;
