import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { useScreenRecognition } from 'hooks/useScreenRecognition';

import Container from 'layout/Container';
import CustomLink from 'common/CustomLink';
import Image from 'common/Image';

import './SlimBanner.scss';

const SlimBanner = ({
  text,
  customLink,
  contentPosition,
  imageDesktop,
  imageMobile,
}: PageContent.SlimBannerType) => {
  const { isMobile } = useScreenRecognition();
  const { linkLabel, color, isLinkExternal, link } = customLink || {};

  return (
    <section className="slim-banner" data-testid="slimBanner">
      <Container className="slim-banner__container">
        <div
          className={classNames('slim-banner__content', {
            [`slim-banner__content--${contentPosition}`]: contentPosition,
          })}
        >
          <h2>{text}</h2>
          {customLink ? (
            <CustomLink
              {...{ linkLabel, color, link, isLinkExternal }}
              className="slim-banner__link"
            />
          ) : null}
        </div>
      </Container>
      <Image
        className="slim-banner__image"
        imageData={isMobile ? imageMobile : imageDesktop}
        alt={isMobile ? imageMobile.altText : imageDesktop.altText}
      />
    </section>
  );
};
export const BannerType = graphql`
  fragment SlimBannerType on SlimBannerType {
    imageDesktop {
      ...UmbracoImage
    }
    imageMobile {
      ...UmbracoImage
    }
    customLink {
      ...CustomLinkType
    }
    text
    contentPosition
  }
`;

export default SlimBanner;
