import React from 'react';
import { Link, navigate } from 'gatsby';

import CustomButton from 'common/CustomButton';

import './FaqContactUs.scss';

const faqContactUs = ({
  title,
  subtitle,
  faqContactUsItems,
  customButton,
}: PageContent.FaqContactUsType) => {
  const { ariaLabel, buttonLabel, buttonLink, backgroundColor } = customButton || {};

  return (
    <div className="faq-contact-us__wrapper" data-testid="faq-contact-us">
      <div className="faq-contact-us">
        <p className="faq-contact-us__title">{title}</p>
        <p className="faq-contact-us__subtitle">{subtitle}</p>
        <div className="faq-contact-us__items">
          {faqContactUsItems?.map(({ question, link: questionLink }) => (
            <div className="faq-contact-us__item" key={question}>
              <Link to={questionLink.url}>{question}</Link>
            </div>
          ))}
        </div>
        <CustomButton
          ariaLabel={ariaLabel}
          backgroundColor={backgroundColor}
          buttonLabel={buttonLabel}
          onClick={() => navigate(buttonLink?.url!)}
        />
      </div>
    </div>
  );
};

export default faqContactUs;
