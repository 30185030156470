import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import FaqContactUs from 'components/FaqContactUs';
import SlimBanner from 'components/SlimBanner';
import runOnUserInteraction from 'utils/runOnUserInteraction';

const ContactUs = ({
  data: {
    contactUs: { urls, seo, heroBanner, faqContactUs, slimBanner, formAddress },
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: ContactUsTypes.ContactUsProps) => {
  const [shouldFormLoad, setShouldFormLoad] = useState(false);

  const { title, keywords, description } = seo;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  useEffect(() => {
    runOnUserInteraction(() => setShouldFormLoad(true));
  }, []);

  return (
    <Layout {...{ crumbsSettings }} headerTransparent>
      <Seo {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...heroBanner} />
      {formAddress && shouldFormLoad ? (
        <section id="main-content">
          <GlobalContactForm
            src={formAddress}
            id="phx-global-form-gatsby"
            title="global-contact-form"
            className="global-contact-form"
          />
        </section>
      ) : null}
      <FaqContactUs {...faqContactUs} />
      <SlimBanner {...slimBanner} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $id: String) {
    contactUs(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      heroBanner {
        ...BannerType
      }
      faqContactUs {
        ...FaqContactUsType
      }
      slimBanner {
        ...SlimBannerType
      }
      formAddress
    }
  }
`;

export default ContactUs;
